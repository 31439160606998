import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  drawTriangle,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  drawPoint,
  drawTri,
  setSize,
  labelIt,
  drawDash,
  placeEggs,
  drawLine,
  setWidth,
  drawIntersection,
  drawPerpP,
  drawAngle,
  createSpace,
  createAxes,
  writeHTMLText
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var graph =createSpace(-3,13,-3,13);
    //graph.suspendUpdate();
    //createAxes(graph);
    // Layering
    graph.options.axis.strokeWidth=0;
    graph.options.layer['image']=17;
    graph.options.layer['text']=19;
    graph.options.layer['line']=16;
    graph.options.layer['point']=17;
    graph.options.layer['glider']=17;
    graph.options.layer['angle']=18;
    graph.options.board.minimizeReflow = 'none';
    graph.options.point.showInfobox =false;
    graph.options.line.highlight=false;
    graph.options.image.highlight=false;
    //graph.options.text.highlight=false;
    graph.options.polygon.highlight=false;
//    graph.options.text.display='internal';
    //Make the board responsive
    makeResponsive(graph);
    // Creat titleStyle
    placeLogo(graph);
    placeTitle(graph, 'Area of Triangle ABC', '(Hover over texts to see areas)');
////////////////////////////////////////////////////////////////////////////////
    var analytics = placeUndo(graph, 'left');
    hoverMe(graph, analytics, 'Previous Step', 0, -5);
    ////////////////////////////////////////////////////
    var erase = placeRedo(graph);
    hoverMe(graph, erase, 'Next Step', 0, -5);
/////////////////////////////////////////////////////////////////////////////
    var i = 0;
//    var eggs = placeEggs(graph);
//    eggs[0].setAttribute({visible:()=>i>=0});
    ////////////////
  //  eggs[1].setAttribute({visible:()=>i>=1});
    ////////////////
  //  eggs[2].setAttribute({visible:()=>i>=2});
    ///////////////////////////All actions go here////////////////////////////////
    //Variables
    var size_cat = 1;
    var size_buttons = 0.5;

    var tx = -0.75;
    var ty = 10;

    var tx2 = 2;
    var ty2 = 9.;

    var sizeOk = 0.5;
    var inpX = tx2 + 10;
    var inpY =ty2;

    let switchStatus = true;

    //Points of images
    var ptA = graph.create('point', [6,6],{snapToGrid: true, face:'o' , name:'A (x_A, y_A)', fillColor: '#5B43FF', strokeColor: 'black', size:3, withLabel:true, fixed:false, visible:true, label:{highlightStrokeColor: '#FF3B3B', offset: [-40, 20], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//
    var ptB = graph.create('point', [2,3],{snapToGrid: true, face:'o' , name:'B (x_B, y_B)', size:3, fillColor: '#5B43FF', strokeColor: 'black', withLabel:true, fixed:false, visible:true, label:{anchorX:'right', highlightStrokeColor: '#FF3B3B', offset: [-10, 0], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    //
    var ptC = graph.create('point', [8,4],{snapToGrid: true, face:'o' , name:'C (x_C, y_C)', size:3, fillColor: '#5B43FF', strokeColor: 'black', withLabel:true, fixed:false, visible:true, label:{anchorX:'left',highlightStrokeColor: '#FF3B3B', offset: [10, 0], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    //
    var ptQ = graph.create('point', [function(){return ptB.X()}, 0], {face:'o' , name:'Q', size:3, fillColor: '#5B43FF', strokeColor: 'black', withLabel:true, fixed:true, visible:true, label:{offset: [5, -25], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    //
    var ptP = graph.create('point', [function(){return ptA.X()}, 0], {face:'o' , name:'P', size:3, fillColor: '#5B43FF', strokeColor: 'black', withLabel:true, fixed:true, visible:true, label:{offset: [5, -25], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    //
    var ptR = graph.create('point', [function(){return ptC.X()}, 0], {face:'o' , name:'R', size:3, fillColor: '#5B43FF', strokeColor: 'black', withLabel:true, fixed:true, visible:true, label:{offset: [5, -25], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

    //Polygons
    var polABQP = graph.create('polygon', [ptA, ptB, ptQ, ptP], {fixed: true, visible: true, highlightFillColor: '#5B43FF', vertices:{visible:false, fixed:true}, borders:{fixed: true, visible:true, strokeColor: '#5B43FF', strokeWidth: 1.5, dash: 3, highlightStrokeColor: '#5B43FF'}, fillColor: 'white', fillOpacity:0.5});
    var polAPRC = graph.create('polygon', [ptA, ptP, ptR, ptC], {fixed: true, visible: true, highlightFillColor: '#5B43FF', vertices:{visible:false, fixed:true}, borders:{fixed: true, visible:true, strokeColor: '#5B43FF', strokeWidth: 1.5, dash: 3, highlightStrokeColor: '#5B43FF'}, fillColor: 'white', fillOpacity:0.5});
    var polBQRC = graph.create('polygon', [ptB, ptQ, ptR, ptC], {fixed: true, visible: true, highlightFillColor: '#5B43FF', vertices:{visible:false, fixed:true}, borders:{fixed: true, visible:true, strokeColor: '#5B43FF', strokeWidth: 1.5, dash: 3, highlightStrokeColor: '#5B43FF'}, fillColor: 'white', fillOpacity:0.5});
//
    var polABC = graph.create('polygon', [ptA, ptB, ptC], {fixed: true, visible: true, highlightFillColor: '#5B43FF', vertices:{visible:false, fixed:true}, borders:{fixed: true, visible:true, strokeColor: '#5B43FF', strokeWidth: 4, highlightStrokeColor: '#5B43FF'}, fillColor: 'white', fillOpacity:0.5});
/////////////////////////texts///////////////////////////////////////
var txtABC = graph.create('text', [tx, ty  , 'Area of &#9651; ABC'],{highlightStrokeColor: 'orange',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);

var txt = graph.create('text', [tx + 1.5, ty  , '='],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txtABQP = graph.create('text', [tx + 3.2, ty  , 'Area of ⏢ ABQP'],{highlightStrokeColor: 'green',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txt = graph.create('text', [tx + 5, ty  , '+'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txtAPRC = graph.create('text', [tx + 6.8, ty  , 'Area of ⏢ APRC'],{highlightStrokeColor: 'blue',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txt = graph.create('text', [tx + 8.5, ty  , '-'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txtBQRC = graph.create('text', [tx + 10.3, ty  , 'Area of ⏢ BQRC'],{highlightStrokeColor: '#FF3B3B',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);

var form = graph.create('text', [tx-1.5, ty2, 'Area of &#9651; ABC = 0.5 ( y_A + y_B ) ( x_A - x_B ) + 0.5 ( y_C + y_A ) ( x_C - x_A ) - 0.5 ( y_B + y_C ) ( x_C - x_B )'], {fixed:true, anchorY: 'top', anchorX: 'left', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}});

//form.setAttribute({anchorX:'middle', anchorY:'middle'});
//////////////////////////Highlights//////////////////////////
txtABC.on('over', function () {
    polABC.setAttribute({visible: true, fillColor: 'orange'});
});
txtABC.on('out', function () {
    polABC.setAttribute({visible: true, fillColor: '#fff'});
});


//Hover text for polygons ABQP
txtABQP.on('over', function () {
    polABQP.setAttribute({visible: true, fillColor: 'green'});
});

txtABQP.on('out', function () {
    polABQP.setAttribute({visible: true, fillColor: '#fff'});
});
//
//Hover text for polygons APRC
txtAPRC.on('over', function () {
    polAPRC.setAttribute({visible: true, fillColor: 'blue'});
});

txtAPRC.on('out', function () {
    polAPRC.setAttribute({visible: true, fillColor: '#fff'});
});
//
//Hover text for polygons BQRC
txtBQRC.on('over', function () {
    polBQRC.setAttribute({visible: true, fillColor: 'red'});
});

txtBQRC.on('out', function () {
    polBQRC.setAttribute({visible: true, fillColor: '#fff'});
});
//////////////////////////////Button Actions////////////////////////////////////
    analytics.on('down', function(){
      if(i>0)
      {
      i-=1;
      }
      else
      {
        i=0;
      }
    });
    erase.on('down', function(){
      if(i<3)
      {
      i+=1;
      }
      else
      {
        i=0;
      }
    })
///////////////////////////////////////////////////////////////////////
    //graph.unsuspendUpdate();
                      },
              }
export default Boxes;
