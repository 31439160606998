<template>
  <div>
    <h3 ref="intro">
      Integers
    </h3>
    <p>
      Any number that is not a fractional number or a decimal is an integer. To better understand integers, we have to look into
      its relation to other integers.
    </p>
    <h3 ref="types">
      Relation to Other Types of Numbers
    </h3>
    <ul class="a">
      <li> <h5> Natural Numbers </h5> </li>
      <p>
        Natural numbers are numbers that we use for counting, ordering and comparing objects &mdash; such as 1, 2, 3, 4, and so on.
        Natural numbers were the first numbers to be discovered.
        So, you can say that any number that is not zero or a fractional number or a negative number is a natural number.
        The set of natural numbers is often denoted by letter \(\mathbb{N}\).
      </p>
      <li> <h5> Whole Number </h5> </li>
      <p>
        If we include 0 in the set of natural numbers then it becomes whole numbers. Thus,  whole numbers are 0, 1, 2, 3, 4, and so on.
        In other words, any number that is not a fractional number or a negative number is a whole number.
        The set of whole numbers is denoted by letter \(\mathbb{W}\).
      </p>
      <li> <h5> Integers</h5> </li>
      <p>
        Now, if we take the set of whole numbers, and include negative natural numbers to it then it becomes set of integers.
        The set of integers is denoted by letter \(\mathbb{Z}\).
      </p>
    </ul>
    <h3 ref="pg">
      MagicGraph | Laws of Exponents
    </h3>
    <p> In this MagicGraph, you will learn about denoting integers on a number line. </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Integers',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Multiplication & Division of Exponents';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Integers', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Integers on a Number Line', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Exponents',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
